import React from 'react'
import Assemble from '../../animations/assemble'
import Mesh from '../../animations/mesh'
import Maze from '../../animations/maze'

// Temporary test page for reviewing / testing animations
// while we work out the GSAP issues with storybook

const TestAnimPage = () => {
  return (
    <main className="">
      <div className="space-x-4 px-3 flex">
        <div className="w-1/3">
          <Assemble />
        </div>
        <div className="w-1/3">
          <Mesh />
        </div>
        <div className="w-1/3 overflow-hidden">
          <Maze />
        </div>
      </div>
    </main>
  )
}

export default TestAnimPage
